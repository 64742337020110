<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="mx-4 mt-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Date</h5>
            <v-col class="pa-0 pt-4">
              <v-dialog
                ref="ApplicationDae"
                :close-on-content-click="false"
                v-model="date_of_application_menu"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_of_application"
                    label="Date"
                    readonly
                    solo
                    dense
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date_of_application"
                  scrollable
                  @input="date_of_application_menu = false"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Request Type</h5>
            <v-select
              solo
              hide-details=""
              dense
              rounded
              v-model="form.request_type"
              :items="requestTypes"
              label="Request Type"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Description</h5>
            <v-textarea
              clearable
              label="Description"
              solo
              dense
              rounded
              v-model="form.description"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <!-- :loading="isLoading" -->
            <!-- @click="login" -->
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      days: 0,
      date_of_application_menu: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      form: {},
      types: [
        { id: "Agreement", value: "Agreement", text: "Agreement" },
        { id: "Compliances", value: "Compliances", text: "Compliances" },
      ],
      months: [
        { text: "January", value: "01" },
        { text: "February", value: "02" },
        { text: "March", value: "03" },
        { text: "April", value: "04" },
        { text: "May", value: "05" },
        { text: "June", value: "06" },
        { text: "July", value: "07" },
        { text: "August", value: "08" },
        { text: "September", value: "09" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: [
        // { text: "2022", value: "2022" },
        // { text: "2023", value: "2023" },
        // { text: "2024", value: "2024" },
      ],
      selectedYear: "",
      selectedFromMonth: "",
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let years = await axios.get("/masters");
      this.years = years.data.data;
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/leave_application_requests/${this.$route.params.leaveId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.approved_by_id = this.user.id;
        this.form.approved_date = moment().format("YYYY-MM-DD");
        let leave = await axios.patch(
          `/leave_application_requests/${this.form.id}`,
          this.form
        );
        this.leave = leave.data;
        this.isLoading = false;
        this.$router.push("/leave-application-forms");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>

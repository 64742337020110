<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0 mt-4">
            <v-row class="mb-4">
              <v-col class="pa-0 pt-4">
                <v-row class="ma-0">
                  <v-col class="pa-0">
                    <v-toolbar-title class="text-left ml-4"
                      >Month:
                    </v-toolbar-title>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col class="pa-0 mx-3">
                    <v-select
                      solo
                      dense
                      rounded
                      hide-details=""
                      v-model="selectedFromMonth"
                      :items="months"
                      label="Month"
                    ></v-select>
                    <!-- @change="updateCalendar" -->
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pa-0 pt-4 mx-4">
                <v-row class="ma-0">
                  <v-col class="pa-0">
                    <v-toolbar-title class="text-left mx-4"
                      >Year:
                    </v-toolbar-title>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col class="pa-0">
                    <v-select
                      solo
                      dense
                      rounded
                      hide-details=""
                      v-model="selectedYear"
                      :items="years"
                      label="Year"
                    ></v-select>
                    <!-- @change="updateCalendar" -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Type</h5>
            <v-select
              solo
              hide-details=""
              dense
              rounded
              v-model="form.type"
              :items="types"
              label="Type"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-4 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Upload:</h5>
            <input class="mt-4" type="file" name="file" ref="file" id="" />
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <!-- :loading="isLoading" -->
            <!-- @click="login" -->
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      days: 0,
      date_of_application_menu: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      form: {},
      types: [
        { id: "Agreement", value: "Agreement", text: "Agreement" },
        { id: "Compliances", value: "Compliances", text: "Compliances" },
      ],
      months: [
        { text: "January", value: "01" },
        { text: "February", value: "02" },
        { text: "March", value: "03" },
        { text: "April", value: "04" },
        { text: "May", value: "05" },
        { text: "June", value: "06" },
        { text: "July", value: "07" },
        { text: "August", value: "08" },
        { text: "September", value: "09" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: [
        // { text: "2022", value: "2022" },
        // { text: "2023", value: "2023" },
        // { text: "2024", value: "2024" },
      ],
      selectedYear: "",
      selectedFromMonth: "",
    };
  },

  async mounted() {
    this.getMasters();
  },

  methods: {
    async getMasters() {
      let years = await axios.get("/masters");
      this.years = years.data.data;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        this.form.site_id = this.site.id;
        this.form.user_id = this.user.id;
        this.form.month = this.selectedFromMonth;
        this.form.year = this.selectedYear;

        let agreement = await axios.post("/agreement_compliances", this.form);
        this.agreement = agreement.data;
        this.handleFileUpload();

        this.isLoading = false;
        this.$router.push("/agreement-complaiances");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;

      this.imagePath = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("id", this.agreement.id);
      formData.append("document_path", this.imagePath);
      await axios
        .post("upload_agreement", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.feedback = response.data.data;
          this.isLoading = false;
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>

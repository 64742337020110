<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-12" rounded="xl">
        <v-row justify="center" class="mt-4" style="max-height: 80px">
          <v-col md="8" lg="6" sm="6" class="px-0 pb-0 mb-0">
            <v-list-item style="bottom: 65px">
              <v-list-item-content>
                <v-list-item-title class="mt-10"
                  ><v-img
                    :src="require('@/assets/voe_imgs/setting.png')"
                    max-height="30"
                    max-width="30"
                  ></v-img
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar size="100">
                <img
                  :src="
                    user.imagepath
                      ? mediaUrl + article.subject_image
                      : require('@/assets/voe_imgs/logo.jpeg')
                  "
                  alt="Profile"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mt-10">
                  <v-img
                    :src="require('@/assets/voe_imgs/camera-icon.png')"
                    max-height="30"
                    max-width="30"
                    @click="openProfileUploadModal()"
                    style="cursor: pointer"
                  ></v-img
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row md="8" lg="6" sm="6" class="ma-0">
          <v-col class="pa-0 blue--text font-weight-bold">
            {{ form.full_name }}
          </v-col>
        </v-row>
        <v-row md="8" lg="6" sm="6" class="ma-0">
          <v-col class="pa-0 caption"> Emp Code: {{ form.employee_id }} </v-col>
        </v-row>

        <v-row class="mx-4 mt-5 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Email</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.email"
              placeholder="Email"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Mobile Number</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.mobile"
              placeholder="Mobile"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Designation</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="designation"
              placeholder="Designation"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <!-- @click="login" -->
          <router-link to="profile-update">
            <v-col align="center">
              <v-btn
                min-width="200"
                :loading="isLoading"
                color="blue"
                rounded
                max-width="100"
                dark
                >Update Profile</v-btn
              >
            </v-col>
          </router-link>
        </v-row>
        <v-row justify="center">
          <router-link to="profile-view">
            <v-col class="text-decoration-underline" align="center">
              View complete profile
            </v-col>
          </router-link>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="profileModal" width="600">
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-container>
          <v-row align="center">
            <v-col sm="9">
              <v-card-title style="color: black"> Upload profile</v-card-title
              ><v-card-text>
                <v-row>
                  <v-col>
                    <input type="file" ref="file" style="min-width: 140px" />
                    <br />
                    <br />
                    <v-btn color="danger" @click="handleFileUpload()">
                      Uplopad
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="success" width="600">
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-container>
          <v-row align="center">
            <v-col sm="9">
              <v-card-title style="color: green">Success</v-card-title
              ><v-card-text>
                <v-row>
                  <v-col>
                    <h3>Profile Uploaded Successfully.</h3>
                    <br />
                    <v-btn color="danger" @click="closedModal()"> Ok </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {},
      designation: "",
      isLoading: false,
      success: false,
      profileModal: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.user.id}`);
      this.form = form.data.data;
      const findDesignation = this.form.user_categories.find(
        (item) => item.category.category_name == "DESIGNATION"
      );

      this.designation = findDesignation
        ? findDesignation.category_list.name
        : "";
      this.isLoading = false;
    },
    openProfileUploadModal() {
      console.log("Click");

      this.profileModal = true;
    },
    closedModal() {
      console.log("Click");

      this.success = false;
    },
    async handleFileUpload() {
      this.userid = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("userid", this.user.id);
      formData.append("imagepath", this.userid);
      await axios
        .post("upload_user_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.feedback = response.data.data;
          this.profileModal = false;
          this.success = true;
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>

<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <v-btn
            fab
            x-small
            class="mx-2 mb-2"
            color="white"
            @click="$router.back()"
          >
            <v-icon light> mdi-arrow-left </v-icon>
          </v-btn>
          <!-- Main Container -->
          <v-card class="mt-4" rounded="xl" color="blue lighten-5" width="auto">
            <!-- Dashboard Container -->
            <v-container>
              <!-- Category Cards -->
              <template
                v-if="
                  user.roles[0].name == 'Employee' ||
                  user.roles[0].name == 'Supervisor'
                "
              >
                <v-row class="pa-0">
                  <v-col
                    class="bold"
                    md="2"
                    sm="2"
                    xs="2"
                    justify="center"
                    style="max-width: 68px"
                  >
                    <v-img
                      :src="require('../../assets/voe_imgs/mark-location.png')"
                      alt="Safety Emergency"
                      max-height="50"
                      max-width="50"
                    ></v-img>
                  </v-col>
                  <v-col
                    class="bold px-0"
                    md="4"
                    sm="4"
                    xs="4"
                    justify="center"
                    styly="max-width:63px"
                  >
                    <p>Current Location</p>
                  </v-col>
                  <v-col
                    v-if="form.length > 0"
                    class="bold mb-4"
                    md="6"
                    sm="6"
                    xs="6"
                    justify="center"
                  >
                    <v-card
                      rounded="xl"
                      elevation="10"
                      min-height="68"
                      min-width="210"
                      class="pa-0 ma-0"
                    >
                      <v-card-text align="center" class="pa-1">
                        {{ form.length > 0 ? form[0].address : "" }}
                      </v-card-text></v-card
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    md="8"
                    lg="8"
                    sm="8"
                    class="pl-0 pb-2"
                    style="
                      min-width: 250px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                      padding-left: 0px;
                      padding-right: 7px;
                    "
                  >
                    <v-card rounded="r-xl" style="min-height: 140px">
                      <v-row justify="center" class="pl-2 mt-0">
                        <v-col class="pa-0" style="min-width: 90px"
                          ><v-card-text>In-time</v-card-text></v-col
                        >
                        <v-col class="px-0 py-2" align="center"
                          ><v-card
                            rounded="xl"
                            width="100"
                            height="40px"
                            justify="center"
                          >
                            <v-card-text class="pa-0 pt-2">{{
                              inData != null ? inData.time : ""
                            }}</v-card-text>
                          </v-card></v-col
                        >
                        <v-col
                          class="px-0 py-0"
                          justify="center"
                          style="margin-left: 10px"
                        >
                          <v-switch
                            v-if="isEnableIn()"
                            color="orange"
                            :loading="isInLoading"
                            align="center"
                            @change="getInCurrentLocation"
                          ></v-switch>
                          <v-switch
                            v-else
                            disabled
                            input-value="true"
                            color="orange"
                            align="center"
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pl-2">
                        <v-col class="pa-0">
                          <v-img
                            :src="require('../../assets/voe_imgs/timer.png')"
                            alt="Logo"
                            width="40"
                            height="40"
                            style="margin-bottom: -10px"
                          ></v-img>
                        </v-col>
                      </v-row>
                      <v-row justify="center" class="pl-2">
                        <v-col class="pa-0 ma-0" style="min-width: 90px"
                          ><v-card-text>Out-time</v-card-text></v-col
                        >
                        <v-col class="px-0 py-2" align="center"
                          ><v-card
                            rounded="xl"
                            width="100"
                            height="40px"
                            justify="center"
                          >
                            <v-card-text class="pa-0 pt-2">{{
                              outData != null ? outData.time : ""
                            }}</v-card-text>
                          </v-card></v-col
                        >
                        <!-- :disabled="form.state == 'OUT'" -->
                        <v-col
                          class="px-0 py-0"
                          justify="center"
                          style="margin-left: 10px"
                        >
                          <!-- Fresh -->
                          <v-switch
                            v-if="isEnableOut() == false && inData.length == 0"
                            disabled
                            :loading="isOutLoading"
                            align="center"
                          ></v-switch>
                          <!-- Can Modified -->
                          <v-switch
                            v-else-if="isEnableOut()"
                            :loading="isOutLoading"
                            color="orange"
                            align="center"
                            @change="getOutCurrentLocation"
                          ></v-switch>
                          <!-- Disabled -->
                          <v-switch
                            v-else
                            disabled
                            input-value="true"
                            color="orange"
                            align="center"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col class="py-0 px-0 pr-0">
                    <v-card rounded="l-xl" style="min-height: 163px">
                      <v-row justify="center" class="ma-0">
                        <v-col align="center" class="pa-0 my-2"
                          ><span class="text-subtitle-1"
                            >My Scoreboard</span
                          ></v-col
                        >
                      </v-row>
                      <v-row class="ma-0 py-2" justify="center">
                        <v-col class="pa-0" align="center">
                          <v-card
                            rounded="circle"
                            style="border: solid #2b8fda 4px"
                            justify="center"
                            height="100"
                            width="100"
                          >
                            <v-card-text align="center" class="py-9 bold"
                              >18.0</v-card-text
                            >
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col align="center">
                    <span>Shift Timings: </span>
                    <span class="blue--text">
                      {{ inData != null ? inData.time : "" }} to
                      {{ outData != null ? outData.time : "" }}</span
                    >
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="user.roles[0].name == 'Employee'">
                  <v-col align="center">
                    <v-select
                      solo
                      dense
                      rounded
                      v-model="selectedMonth"
                      :items="months"
                      label="Month"
                      @change="getMonthlyAttendanceData()"
                    ></v-select>
                  </v-col>
                  <v-col align="center">
                    <v-select
                      solo
                      dense
                      rounded
                      v-model="selectedYear"
                      :items="years"
                      label="Year"
                      @change="getMonthlyAttendanceData()"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="ma-0" v-if="user.roles[0].name == 'Employee'">
                  <v-col class="pa-0">
                    <v-card rounded="r-xl" class="pa-4">
                      <v-row justify="center">
                        <!-- v-bind="attrs"
                            v-on="on" -->
                        <v-col align="center"
                          ><v-icon small dark color="green">
                            mdi-circle
                          </v-icon>
                          Present</v-col
                        >
                        <!-- v-bind="attrs"
                            v-on="on" -->
                        <v-col align="center"
                          ><v-icon small dark color="red"> mdi-circle </v-icon
                          >Absent</v-col
                        >
                        <!-- v-bind="attrs"
                            v-on="on" -->
                        <!-- <v-col align="center"
                          ><v-icon small dark color="purple">
                            mdi-circle </v-icon
                          >Week-Off</v-col
                        > -->
                      </v-row>
                      <v-calendar
                        v-model="selectedDate"
                        :events="events"
                        @click:event="showEvent"
                        type="month"
                      ></v-calendar>
                      <v-menu
                        v-model="selectedOpen"
                        :close-on-content-click="false"
                        :activator="selectedElement"
                      >
                        <!-- offset-x -->
                        <v-card
                          color="grey lighten-4"
                          min-width="250px"
                          flat
                          rounded="xl"
                        >
                          <v-card-text>
                            <span v-html="selectedEvent.detail"> </span>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  class="ma-0 mt-4"
                  justify="center"
                  v-if="user.roles[0].name == 'Employee'"
                >
                  <v-col class="pa-0 mx-2" align="center">
                    <v-card rounded="xl">
                      <v-card-text
                        >Present: {{ userAttendances.present }}</v-card-text
                      >
                    </v-card>
                  </v-col>
                  <v-col class="pa-0 mx-2" align="center">
                    <v-card rounded="xl">
                      <v-card-text
                        >Absent: {{ userAttendances.absent }}</v-card-text
                      >
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  class="ma-0 mt-4"
                  justify="center"
                  v-if="user.roles[0].name == 'Employee'"
                >
                  <v-col class="pa-0 mx-2" align="center">
                    <v-card rounded="xl">
                      <v-card-text
                        >Half Day:
                        {{ userAttendances.less_than_5_hrs }}</v-card-text
                      >
                    </v-card>
                  </v-col>
                  <v-col class="pa-0 mx-2" align="center">
                    <v-card rounded="xl">
                      <v-card-text>Penalty: 0</v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <template v-if="user.roles[0].name == 'Supervisor'">
                <v-row class="ma-0">
                  <v-col class="pa-0">
                    <v-row class="ma-0 mt-4">
                      <v-col class="pa-0">
                        <h4>Select Client:</h4>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0">
                      <v-col class="pa-0">
                        <v-select
                          solo
                          hide-details=""
                          dense
                          rounded
                          v-model="form.site_id"
                          :items="sites"
                          label="Client"
                          @change="getgroups()"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 mt-2" v-if="form.site_id != 'All'">
                      <v-col class="pa-0">
                        <h4>Select Group:</h4>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0" v-if="form.site_id != 'All'">
                      <v-col class="pa-0">
                        <v-select
                          solo
                          hide-details=""
                          dense
                          rounded
                          v-model="form.group_id"
                          :items="groups"
                          label="Group"
                          @change="getGroupDivisions()"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row class="ma-0 mt-3">
                      <v-col class="pa-0">
                        <h4>Select Sesstion:</h4>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0">
                      <v-col class="pa-0">
                        <v-select
                          solo
                          hide-details=""
                          dense
                          rounded
                          v-model="form.session_type_id"
                          :items="sessionsTypes"
                          label="Session Type"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="pa-0">
                    <v-row class="ma-0">
                      <v-col
                        class="pa-0 mt-3 ml-3 mr-0"
                        style="min-width: 80px"
                        align="center"
                      >
                        <v-avatar size="60">
                          <v-img src="@/assets/voe_imgs/logo.jpeg"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col class="pa-0 mt-4">
                        <v-row class="ma-0">
                          <v-col class="pa-0"
                            ><span>{{
                              user.full_name.split(" ")[0]
                            }}</span></v-col
                          >
                        </v-row>
                        <v-row class="ma-0">
                          <v-col class="pa-0"
                            ><span class="blue--text">{{
                              user.roles[0].name
                            }}</span></v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 mt-4 mx-4" v-if="form.site_id != 'All'">
                      <v-col class="pa-0">
                        <h4>Select Division:</h4>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 ml-4" v-if="form.site_id != 'All'">
                      <v-col class="pa-0">
                        <v-select
                          solo
                          hide-details=""
                          dense
                          rounded
                          v-model="form.group_division_id"
                          :items="group_divisions"
                          label="Division"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 mt-2 mx-4">
                      <v-col class="pa-0">
                        <h4>Select Month:</h4>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 ml-4">
                      <v-col class="pa-0">
                        <!-- <v-dialog
                          :close-on-content-click="false"
                          ref="dialog"
                          v-model="modal"
                          :return-value.sync="form.date"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="form.month_year_filter_formatted"
                              label="Select Month - Year"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              solo
                              dense
                              rounded
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="form.date"
                            type="month"
                            scrollable
                            @change="customMonthFormat()"
                            @input="modal = false"
                          >
                          </v-date-picker>
                        </v-dialog> -->
                        <v-dialog
                          :close-on-content-click="false"
                          ref="dialog"
                          v-model="modal"
                          :return-value.sync="form.date"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="form.month_year_filter_formatted"
                              label="Select Month - Year"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              solo
                              dense
                              rounded
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="form.date"
                            type="month"
                            scrollable
                            @change="customMonthFormat"
                            @input="modal = false"
                          ></v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <template v-if="users.length == 0 && isLoading == true">
                  <v-row class="ma-0 my-4">
                    <v-col class="pa-0">
                      <v-card rounded="xl" elevation="10">
                        <v-row class="ma-0">
                          <v-col class="pa-4">
                            <template>
                              <span
                                >Kindly Wait we are fetching supervisor
                                data...</span
                              >
                            </template>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-else-if="users.length == 0 && isLoading == false">
                  <v-row class="ma-0 my-4">
                    <v-col class="pa-0">
                      <v-card rounded="xl" elevation="10">
                        <v-row class="ma-0">
                          <v-col class="pa-4">
                            <template>
                              <span
                                >Select month and year to view supervisor
                                attendance.</span
                              >
                            </template>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row
                    class="ma-0"
                    v-for="(supervisor, su) in users"
                    :key="`super${su}`"
                  >
                    <v-col class="pa-0">
                      <v-card rounded="xl" elevation="10">
                        <v-row class="ma-0">
                          <v-col class="pa-4">
                            <template>
                              <v-simple-table fixed-header>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th
                                        v-for="c in totalDaysInMonth"
                                        :key="`days${c}`"
                                      >
                                        {{ c }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(user, us) in supervisor[
                                        'employees'
                                      ]"
                                      :key="`user${us}`"
                                    >
                                      <td
                                        v-for="c in totalDaysInMonth"
                                        :key="`days${c}`"
                                        style="min-width: 50px"
                                      >
                                        <v-icon
                                          v-if="user[c] == 'A'"
                                          color="red"
                                          >mdi-circle</v-icon
                                        >

                                        <v-icon
                                          v-if="user[c] == 'P'"
                                          color="green"
                                          >mdi-circle</v-icon
                                        >

                                        <v-icon
                                          v-if="user[c] == 'R'"
                                          color="blue"
                                          >mdi-circle</v-icon
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template
                  v-if="employees.length == 0 && isEmployeeLoading == true"
                >
                  <v-row class="ma-0 my-4">
                    <v-col class="pa-0">
                      <v-card rounded="xl" elevation="10">
                        <v-row class="ma-0">
                          <v-col class="pa-4">
                            <template>
                              <span
                                >Kindly Wait we are fetching employees
                                data...</span
                              >
                            </template>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template
                  v-else-if="
                    employees.length == 0 && isEmployeeLoading == false
                  "
                >
                  <v-row class="ma-0 my-4">
                    <v-col class="pa-0">
                      <v-card rounded="xl" elevation="10">
                        <v-row class="ma-0">
                          <v-col class="pa-4">
                            <template>
                              <span
                                >Select site, month and year to view employee
                                attendance.</span
                              >
                            </template>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row
                    class="ma-0 my-4"
                    v-for="(user, us) in employees"
                    :key="`${us}`"
                  >
                    <v-col class="pa-0">
                      <v-card rounded="xl" elevation="10">
                        <v-row class="ma-0">
                          <v-col class="pa-4">
                            <v-text>{{ us }}</v-text>
                            <!-- <template>
                              <v-row class="ma-0 mt-4">
                                <v-col class="pa-0" style="max-width: 50px">
                                  <v-img
                                    :src="
                                      require('../../assets/voe_imgs/group-profile.png')
                                    "
                                    alt="Site"
                                    width="40"
                                    height="40"
                                  ></v-img>
                                </v-col>
                                <v-col class="pa-0 mt-2">
                                  <span style="font-size: 15px"
                                    >Employees:</span
                                  >
                                </v-col>
                                <v-col class="pa-0 mt-2">
                                  <span class="orange--text">{{
                                    user["total_employees"]
                                  }}</span>
                                </v-col>
                                <v-col class="pa-0" style="max-width: 50px">
                                  <v-img
                                    :src="
                                      require('../../assets/voe_imgs/reliever.png')
                                    "
                                    alt="Site"
                                    width="40"
                                    height="40"
                                  ></v-img>
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 150px"
                                >
                                  <span style="font-size: 15px"
                                    >Relievers:</span
                                  >
                                </v-col>
                                <v-col class="pa-0 mt-2">
                                  <span class="orange--text">{{
                                    user["reliever_count"]
                                  }}</span>
                                </v-col>
                              </v-row>
                              <v-row class="ma-0 mt-4">
                                <v-col class="pa-0" style="max-width: 50px">
                                  <v-img
                                    :src="
                                      require('../../assets/voe_imgs/present.png')
                                    "
                                    alt="Site"
                                    width="40"
                                    height="40"
                                  ></v-img>
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 200px"
                                >
                                  <span style="font-size: 15px">Presents:</span>
                                </v-col>
                                <v-col class="pa-0 mt-2">
                                  <span class="orange--text">{{
                                    user["present_count"]
                                  }}</span>
                                </v-col>
                                <v-col class="pa-0" style="max-width: 50px">
                                  <v-img
                                    :src="
                                      require('../../assets/voe_imgs/absent.png')
                                    "
                                    alt="Site"
                                    width="40"
                                    height="40"
                                  ></v-img>
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 150px"
                                >
                                  <span style="font-size: 15px">Absents:</span>
                                </v-col>
                                <v-col class="pa-0 mt-2">
                                  <span class="orange--text">{{
                                    user["absent_count"]
                                  }}</span>
                                </v-col>
                              </v-row>
                            </template> -->
                            <template>
                              <v-row class="ma-0 mt-4">
                                <!-- Employees Section -->
                                <v-col class="pa-0" style="max-width: 50px">
                                  <v-img
                                    src="@/assets/voe_imgs/group-profile.png"
                                    alt="Employees"
                                    width="40"
                                    height="40"
                                  ></v-img>
                                </v-col>
                                <v-col class="pa-0 mt-2">
                                  <span class="text-subtitle-2"
                                    >Employees:</span
                                  >
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 25px"
                                >
                                  <span class="orange--text text-subtitle-2">{{
                                    user.total_employees
                                  }}</span>
                                </v-col>

                                <!-- Relievers Section -->
                                <v-col class="pa-0" style="max-width: 50px">
                                  <v-img
                                    src="@/assets/voe_imgs/reliever.png"
                                    alt="Relievers"
                                    width="40"
                                    height="40"
                                  ></v-img>
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 150px"
                                >
                                  <span class="text-subtitle-2"
                                    >Relievers:</span
                                  >
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 25px"
                                >
                                  <span class="orange--text text-subtitle-2">{{
                                    user.reliever_count
                                  }}</span>
                                </v-col>
                              </v-row>

                              <v-row class="ma-0 mt-4">
                                <!-- Presents Section -->
                                <v-col class="pa-0" style="max-width: 50px">
                                  <v-img
                                    src="@/assets/voe_imgs/present.png"
                                    alt="Presents"
                                    width="40"
                                    height="40"
                                  ></v-img>
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 200px"
                                >
                                  <span class="text-subtitle-2">Presents:</span>
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 25px"
                                >
                                  <span class="orange--text text-subtitle-2">{{
                                    user.present_count
                                  }}</span>
                                </v-col>

                                <!-- Absents Section -->
                                <v-col class="pa-0" style="max-width: 50px">
                                  <v-img
                                    src="@/assets/voe_imgs/absent.png"
                                    alt="Absents"
                                    width="40"
                                    height="40"
                                  ></v-img>
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 150px"
                                >
                                  <span class="text-subtitle-2">Absents:</span>
                                </v-col>
                                <v-col
                                  class="pa-0 mt-2"
                                  style="max-width: 25px"
                                >
                                  <span class="orange--text text-subtitle-2">{{
                                    user.absent_count
                                  }}</span>
                                </v-col>
                              </v-row>
                            </template>

                            <template>
                              <v-simple-table fixed-header>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th>Sr. No</th>
                                      <th>Emp Code</th>
                                      <th class="sticky-header">Name</th>
                                      <th
                                        v-for="c in totalDaysInMonth"
                                        :key="`days${c}`"
                                      >
                                        {{ c }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(user, us) in user['employees']"
                                      :key="`user${us}`"
                                    >
                                      <td style="min-width: 50px">
                                        {{ us + 1 }}
                                      </td>
                                      <td style="min-width: 96px">
                                        {{ user.employee_id }}
                                      </td>
                                      <td
                                        style="min-width: 200px"
                                        class="sticky-column"
                                        @click="
                                          (calendarDialog = true),
                                            getMonthlyAttendanceData(
                                              user.user_id
                                            ),
                                            (displayEmployeeName =
                                              user.full_name)
                                        "
                                      >
                                        {{ user.full_name }}
                                      </td>

                                      <td
                                        v-for="c in totalDaysInMonth"
                                        :key="`days${c}`"
                                        style="min-width: 50px"
                                      >
                                        <v-icon
                                          v-if="user[c] == 'A'"
                                          color="red"
                                          >mdi-circle</v-icon
                                        >

                                        <v-icon
                                          v-if="user[c] == 'P'"
                                          color="green"
                                          @click="
                                            attendanceDetails(user[`punch` + c])
                                          "
                                          >mdi-circle</v-icon
                                        >

                                        <v-icon
                                          v-if="user[c] == 'R'"
                                          color="blue"
                                          >mdi-circle</v-icon
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </template>
              <template v-if="user.roles[0].name == 'Client'">
                <v-row class="ma-0">
                  <v-col class="pa-0" style="max-width: 50px">
                    <v-img
                      :src="require('../../assets/voe_imgs/site.png')"
                      alt="Site"
                      width="40"
                      height="40"
                    ></v-img>
                  </v-col>
                  <v-col class="pa-0">
                    <h4>Select Group :</h4>
                  </v-col>
                </v-row>
                <v-row class="ma-0 mt-2">
                  <v-col class="pa-0">
                    <v-select
                      solo
                      hide-details=""
                      dense
                      rounded
                      v-model="form.group_id"
                      :items="groups"
                      label="Group"
                      @change="getGroupDivisions()"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="ma-0 mt-4">
                  <v-col class="pa-0" style="max-width: 50px">
                    <v-img
                      :src="
                        user.imagepath
                          ? mediaUrl + article.subject_image
                          : require('../../assets/voe_imgs/site.png')
                      "
                      alt="Site"
                      width="40"
                      height="40"
                    ></v-img>
                  </v-col>
                  <v-col class="pa-0 mt-3">
                    <h4>Select Division:</h4>
                  </v-col>
                </v-row>
                <v-row class="ma-0 mt-2">
                  <v-col class="pa-0">
                    <v-select
                      solo
                      hide-details=""
                      dense
                      rounded
                      v-model="form.group_division_id"
                      :items="group_divisions"
                      label="Division"
                    ></v-select>
                  </v-col>
                </v-row>

                <template>
                  <v-row class="ma-0 mt-4">
                    <!-- Employees Section -->
                    <v-col class="pa-0" style="max-width: 50px">
                      <v-img
                        src="@/assets/voe_imgs/group-profile.png"
                        alt="Employees"
                        width="40"
                        height="40"
                      ></v-img>
                    </v-col>
                    <v-col class="pa-0 mt-2">
                      <span class="text-subtitle-2">Employees:</span>
                    </v-col>
                    <v-col class="pa-0 mt-2" style="max-width: 25px">
                      <span class="orange--text text-subtitle-2">{{
                        count
                      }}</span>
                    </v-col>

                    <!-- Relievers Section -->
                    <v-col class="pa-0" style="max-width: 50px">
                      <v-img
                        src="@/assets/voe_imgs/reliever.png"
                        alt="Relievers"
                        width="40"
                        height="40"
                      ></v-img>
                    </v-col>
                    <v-col class="pa-0 mt-2" style="max-width: 150px">
                      <span class="text-subtitle-2">Relievers:</span>
                    </v-col>
                    <v-col class="pa-0 mt-2" style="max-width: 25px">
                      <span class="orange--text text-subtitle-2">{{
                        reliever_count
                      }}</span>
                    </v-col>
                  </v-row>

                  <v-row class="ma-0 mt-4">
                    <!-- Presents Section -->
                    <v-col class="pa-0" style="max-width: 50px">
                      <v-img
                        src="@/assets/voe_imgs/present.png"
                        alt="Presents"
                        width="40"
                        height="40"
                      ></v-img>
                    </v-col>
                    <v-col class="pa-0 mt-2" style="max-width: 200px">
                      <span class="text-subtitle-2">Presents:</span>
                    </v-col>
                    <v-col class="pa-0 mt-2" style="max-width: 25px">
                      <span class="orange--text text-subtitle-2">{{
                        present_count
                      }}</span>
                    </v-col>

                    <!-- Absents Section -->
                    <v-col class="pa-0" style="max-width: 50px">
                      <v-img
                        src="@/assets/voe_imgs/absent.png"
                        alt="Absents"
                        width="40"
                        height="40"
                      ></v-img>
                    </v-col>
                    <v-col class="pa-0 mt-2" style="max-width: 150px">
                      <span class="text-subtitle-2">Absents:</span>
                    </v-col>
                    <v-col class="pa-0 mt-2" style="max-width: 25px">
                      <span class="orange--text text-subtitle-2">{{
                        absent_count
                      }}</span>
                    </v-col>
                  </v-row>
                </template>

                <v-row class="ma-0 mt-3">
                  <v-col class="pa-0">
                    <h4>Select Sesstion:</h4>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col class="pa-0">
                    <v-select
                      solo
                      hide-details=""
                      dense
                      rounded
                      v-model="form.session_type_id"
                      :items="sessionsTypes"
                      label="Session Type"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="ma-0 mt-4">
                  <v-col class="pa-0">
                    <v-row class="ma-0">
                      <v-col class="pa-0">
                        <h4>Select Month:</h4>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0">
                      <v-col class="pa-0">
                        <v-dialog
                          :close-on-content-click="false"
                          ref="dialog"
                          v-model="modal"
                          :return-value.sync="form.date"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="form.month_year_filter_formatted"
                              label="Select Month - Year"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              solo
                              dense
                              rounded
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="form.date"
                            type="month"
                            scrollable
                            @change="customMonthFormat()"
                            @input="modal = false"
                          >
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <template
                  v-if="employees.length == 0 && isEmployeeLoading == true"
                >
                  <v-row class="ma-0 my-4">
                    <v-col class="pa-0">
                      <v-card rounded="xl" elevation="10">
                        <v-row class="ma-0">
                          <v-col class="pa-4">
                            <template>
                              <span
                                >Kindly Wait we are fetching employees
                                data...</span
                              >
                            </template>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template
                  v-else-if="
                    employees.length == 0 && isEmployeeLoading == false
                  "
                >
                  <v-row class="ma-0 my-4">
                    <v-col class="pa-0">
                      <v-card rounded="xl" elevation="10">
                        <v-row class="ma-0">
                          <v-col class="pa-4">
                            <template>
                              <span
                                >Select month and year to view employee
                                attendance.</span
                              >
                            </template>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row
                    class="ma-0 my-4"
                    v-for="(user, us) in employees"
                    :key="`${us}`"
                  >
                    <v-col class="pa-0">
                      <v-card rounded="xl" elevation="10">
                        <v-row class="ma-0">
                          <v-col class="pa-4">
                            <template>
                              <v-simple-table fixed-header>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th>Sr. No</th>
                                      <th>Emp Code</th>
                                      <th class="sticky-header">Name</th>
                                      <th
                                        v-for="c in totalDaysInMonth"
                                        :key="`days${c}`"
                                      >
                                        {{ c }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(user, us) in user['employees']"
                                      :key="`user${us}`"
                                    >
                                      <td style="min-width: 50px">
                                        {{ us + 1 }}
                                      </td>
                                      <td style="min-width: 96px">
                                        {{ user.employee_id }}
                                      </td>
                                      <td
                                        style="min-width: 200px"
                                        class="sticky-column"
                                        @click="
                                          (calendarDialog = true),
                                            getMonthlyAttendanceData(
                                              user.user_id
                                            ),
                                            (displayEmployeeName =
                                              user.full_name)
                                        "
                                      >
                                        {{ user.full_name }}
                                      </td>

                                      <td
                                        v-for="c in totalDaysInMonth"
                                        :key="`days${c}`"
                                        style="min-width: 50px"
                                      >
                                        <v-icon
                                          v-if="user[c] == 'A'"
                                          color="red"
                                          >mdi-circle</v-icon
                                        >

                                        <v-icon
                                          v-if="user[c] == 'P'"
                                          color="green"
                                          @click="
                                            attendanceDetails(user[`punch` + c])
                                          "
                                          >mdi-circle</v-icon
                                        >

                                        <v-icon
                                          v-if="user[c] == 'R'"
                                          color="blue"
                                          >mdi-circle</v-icon
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </template>
            </v-container>

            <v-btn
              v-if="user.roles[0].name == 'Supervisor'"
              to="/attendance-create"
              color="white"
              dark
              absolute
              bottom
              right
              fab
            >
              <v-icon color="black">mdi-plus</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <template>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card rounded="xl">
          <v-row class="ma-0 pa-3">
            <v-col class="pa-0">In Time</v-col>
            <v-col class="pa-0"
              >:
              {{
                employeeAttendanceDetails.in_time
                  ? employeeAttendanceDetails.in_time
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-3">
            <v-col class="pa-0">Out Time</v-col>
            <v-col class="pa-0"
              >:
              {{
                employeeAttendanceDetails.out_time
                  ? employeeAttendanceDetails.out_time
                  : ""
              }}</v-col
            >
          </v-row>
          <v-row class="ma-0 pa-3">
            <v-col class="pa-0">Current Location</v-col>
            <v-col class="pa-0"
              >:
              {{
                employeeAttendanceDetails.address
                  ? employeeAttendanceDetails.address
                  : ""
              }}</v-col
            >
          </v-row>

          <v-card-actions justify="center">
            <v-row justify="center" class="my-4">
              <v-btn
                color="blue darken-1"
                rounded
                @click="dialog = false"
                class="mx-2"
                dark
              >
                OK
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="userDailyTaskDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="userDailyTaskDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title> User daily summaries</v-toolbar-title>
            </v-toolbar>
            <v-list three-line subheader>
              <v-subheader
                >{{ displayEmployeeName }}'s daily summaries</v-subheader
              >
            </v-list>
            <template>
              <v-row class="pa-0">
                <v-col class="ma-3">
                  <v-row
                    class="ma-0"
                    v-for="(user_daily_task, index) in user_daily_tasks"
                    :key="index"
                  >
                    <v-col class="pa-0" style="max-width: 70px">
                      <h5 class="mt-2">
                        {{ user_daily_task.from_time }} <br />
                        To<br />
                        {{ user_daily_task.to_time }}
                      </h5>
                    </v-col>
                    <v-col class="pa-0 pl-2">
                      <v-card rounded="xl">
                        <v-row class="ma-2">
                          <v-col class="pa-2">
                            <v-row>
                              <v-col style="min-width: 160px; min-height: 50px">
                                <span
                                  class="blue--text subtitle-2 text--darken-2 font-weight-bold"
                                >
                                  {{
                                    user_daily_task.daily_task
                                      ? user_daily_task.daily_task.code
                                      : ""
                                  }}
                                </span>
                              </v-col>
                              <v-col
                                class="text-right pa-0 ma-0 mt-3 mr-4"
                                style="max-width: fit-content"
                              >
                                <span class="subtitle-2 text--darken-2">
                                  {{
                                    user_daily_task.is_done == 1
                                      ? "Completed"
                                      : "Pending"
                                  }}
                                </span>
                              </v-col>
                            </v-row>
                            <v-row class="ma-0">
                              <v-col style="min-width: 90px" class="pa-0">
                                <span
                                  class="grey--text caption font-weight-bold"
                                >
                                  Remarks:
                                  {{
                                    user_daily_task.description
                                      ? user_daily_task.description
                                      : ""
                                  }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <template v-if="user_daily_tasks.length == 0">
              <v-row justify="center">
                <v-col align="center" style="min-height: 100px">
                  <span> No tasks for the date. </span>
                </v-col>
              </v-row>
            </template>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="calendarDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="calendarDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title> Monthly Attendance</v-toolbar-title>
            </v-toolbar>
            <v-list three-line subheader>
              <v-subheader
                >{{ displayEmployeeName }}'s Monthly Attendance</v-subheader
              >
              <v-row class="pa-0">
                <v-col class="ma-3">
                  <v-calendar
                    v-model="selectedDate"
                    :events="events"
                    @click:event="showEvent"
                    @click:date="getUserDailyTasks"
                    type="month"
                  ></v-calendar>
                </v-col>
              </v-row>
            </v-list>

            <v-list>
              <v-list-item>
                <v-row align="center" class="w-100">
                  <v-col cols="6">
                    <v-list-item-title>Total Absent</v-list-item-title>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item-title class="text-right">
                      {{ userAttendances.absent }}
                    </v-list-item-title>
                  </v-col>
                </v-row>
              </v-list-item>

              <v-list-item>
                <v-row align="center" class="w-100">
                  <v-col cols="6">
                    <v-list-item-title>Total Present</v-list-item-title>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item-title class="text-right">
                      {{ userAttendances.present }}
                    </v-list-item-title>
                  </v-col>
                </v-row>
              </v-list-item>

              <v-list-item>
                <v-row align="center" class="w-100">
                  <v-col cols="6">
                    <v-list-item-title>Total Half day</v-list-item-title>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item-title class="text-right">
                      {{ userAttendances.less_than_5_hrs }}
                    </v-list-item-title>
                  </v-col>
                </v-row>
              </v-list-item>

              <v-list-item>
                <v-row align="center" class="w-100">
                  <v-col cols="6">
                    <v-list-item-title>Total Overtime</v-list-item-title>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item-title class="text-right">
                      {{ userAttendances.greater_than_5_hrs }}
                    </v-list-item-title>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row align="center" class="w-100">
                  <v-col cols="6">
                    <v-list-item-title>Total Penalty</v-list-item-title>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item-title class="text-right">
                      0
                    </v-list-item-title>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item v-if="userAttendances.is_smily == 1">
                <v-row align="center" class="w-100">
                  <v-col cols="6">
                    <v-list-item-title
                      ><v-icon size="60" color="yellow"
                        >mdi-emoticon-outline</v-icon
                      ></v-list-item-title
                    >
                  </v-col>
                </v-row>
              </v-list-item>

              <!-- <v-list-item>
                            <v-row align="center" class="w-100">
                              <v-col cols="6">
                                <v-list-item-title
                                  >Average Working Hrs</v-list-item-title
                                >
                              </v-col>
                              <v-col cols="6">
                                <v-list-item-title class="text-right">
                                  {{ userAttendances.avg_working_hrs }}
                                </v-list-item-title>
                              </v-col>
                            </v-row>
                          </v-list-item> -->

              <!-- <v-list-item>
                            <v-row align="center" class="w-100">
                              <v-col cols="6">
                                <v-list-item-title
                                  >Total Worked Hrs Day</v-list-item-title
                                >
                              </v-col>
                              <v-col cols="6">
                                <v-list-item-title class="text-right">
                                  {{ userAttendances.total_worked_hrs_day }}
                                </v-list-item-title>
                              </v-col>
                            </v-row>
                          </v-list-item> -->
            </v-list>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-container class="footer-text mt-8">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {},
      inData: {},
      outData: {},
      currLocation: "",
      isInLoading: false,
      isOutLoading: false,
      currentLocation: {
        latitude: "",
        longitude: "",
      },
      address: "",
      in_time: "",
      out_time: "",
      error: null,
      inState: false,
      outState: false,
      //selectedMonth: "",
      //selectedYear: "",
      selectedMonth: moment().format("MM"),
      selectedYear: moment().format("YYYY"),
      selectedDate: new Date(),
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],

      userAttendances: [],
      months: [
        { text: "January", value: "01" },
        { text: "February", value: "02" },
        { text: "March", value: "03" },
        { text: "April", value: "04" },
        { text: "May", value: "05" },
        { text: "June", value: "06" },
        { text: "July", value: "07" },
        { text: "August", value: "08" },
        { text: "September", value: "09" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: [],

      modal: false,
      form: {
        date: new Date().toISOString().substr(0, 7),
        month_year_filter_formatted:
          moment().format("MMM") + "-" + moment().format("YYYY"),
      },

      totalDaysInMonth: "",
      users: [],
      employees: [],
      employeeAttendanceDetails: [],
      dialog: false,
      group_divisions: [],
      groups: [],
      isEmployeeLoading: false,
      isLoading: false,
      sessionsTypes: [
        { text: "ALL", value: "All" },
        { text: "ABSENT", value: "Absent" },
      ],
      sites: [{ text: "ALL", value: "All" }],
      calendarDialog: false,
      displayEmployeeName: "",
      employee_id: "",
      user_daily_tasks: [],
      userDailyTaskDialog: false,
      daysInMonth: "",
      count: 0,
      absent_count: 0,
      present_count: 0,
      reliever_count: 0,
    };
  },

  async mounted() {
    if (
      this.user.roles[0].name == "Client" ||
      this.user.roles[0].name == "Supervisor"
    ) {
      await this.customMonthFormat();
    }

    await this.getData();
    this.getMasters();
    this.getgroups();
    if (this.user.roles[0].name == "Employee") {
      await this.getMonthlyAttendanceData();
    }
    this.form.user_id = this.user.id;
  },

  methods: {
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      let years = await axios.get("/masters");
      this.years = years.data.data;

      // this.groups = masters.groups;
      // masters.groups.forEach((group) => {
      //   this.groups.push({
      //     id: group.id,
      //     text: group.name,
      //     value: group.id,
      //   });
      // });

      masters.session_types.forEach((session_type) => {
        this.sessionsTypes.push({
          id: session_type.id,
          text: session_type.description,
          value: session_type.id,
        });
      });
      masters.sites.forEach((site) => {
        this.sites.push({
          id: site.id,
          text: site.name,
          value: site.id,
        });
      });
      this.refreshData();
    },
    async getgroups() {
      this.groups = [];
      let site_id = "";
      if (this.user.roles[0].name == "Client") {
        site_id = this.site.id;
      } else {
        site_id = this.form.site_id;
      }
      let groups = await axios.get(`/groups?site_id=${site_id}`);
      groups.data.data.forEach((group) => {
        this.groups.push({
          id: group.id,
          text: group.name,
          value: group.id,
        });
      });

      // console.log(this.groups);
    },
    async getGroupDivisions() {
      this.group_divisions = [];
      let group_divisions = await axios.get(
        `/groups/${this.form.group_id}/group_divisions`
      );
      group_divisions.data.data.forEach((group_division) => {
        this.group_divisions.push({
          id: group_division.id,
          text: group_division.division_name,
          value: group_division.id,
        });
      });

      console.log(this.group_divisions);
    },
    attendanceDetails(attendanceDetails) {
      this.dialog = true;
      this.employeeAttendanceDetails = attendanceDetails;
      console.log(attendanceDetails);
    },
    customMonthFormat() {
      let date = this.form.date;
      let year = date.split("-")[0];
      let month = moment(date.split("-")[1], "M").format("MMM");
      this.form.month_year_filter_formatted = `${month}-${year}`;
      this.updateMonth();
      this.getLoggedInUserPunchData();
      this.getEmployeesPunchData();
    },
    updateMonth() {
      let MonthYear = this.form.month_year_filter_formatted.split("-");
      let month = MonthYear[0];
      let year = MonthYear[1];
      this.month_name = moment(month, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        year + "-" + month,
        "YYYY-M"
      ).daysInMonth();
    },
    async getLoggedInUserPunchData() {
      try {
        this.isLoading = true;
        let MonthYear = this.form.month_year_filter_formatted.split("-");
        let month = moment().month(MonthYear[0]).format("M");
        let session_type = this.form.session_type_id ?? "All";
        let site_id = this.site.id;
        let year = MonthYear[1];
        this.users = [];
        let query = `monthId=${month}&year=${year}&user_id=${this.user.id}&session_type_id=${session_type}&site_id=${site_id}`;
        let users = await axios.get(`/user_punches/filter?${query}`);
        this.users = users.data.data;
        console.log(this.users);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getEmployeesPunchData() {
      try {
        this.isEmployeeLoading = true;
        let MonthYear = this.form.month_year_filter_formatted.split("-");
        let month = moment().month(MonthYear[0]).format("M");
        console.log(month);
        this.selectedSite = this.form.site_id;
        // let session_type = 128;
        let session_type = this.form.session_type_id ?? "All";
        let group_id = this.form.group_id ?? "";
        let group_division_id = this.form.group_division_id ?? "";
        let site_id = "";
        if (this.user.roles[0].name == "Client") {
          site_id = this.site.id;
          console.log(this.site.id);
        } else {
          site_id = this.form.site_id ?? "";
        }

        let year = MonthYear[1];
        this.employees = [];
        // let employees = await axios.get(
        //   `/user_punches/filter?monthId=${month}&year=${year}&groupId=${this.selectedSite}&session_type_id=${session_type}`
        // );
        let query = `monthId=${month}&year=${year}&site_id=${site_id}&group_id=${group_id}&group_dividion_id=${group_division_id}&session_type_id=${session_type}`;
        let employees = await axios.get(`/user_punches/filter?${query}`);
        this.employees = employees.data.data;
        this.count = employees.data.count;
        this.absent_count = employees.data.overall_counts.absent_count;
        this.present_count = employees.data.overall_counts.present_count;
        this.reliever_count = employees.data.overall_counts.reliever_count;
        console.log(this.employees.length);

        this.employees = this.employees.filter(
          (employee) => employee.employee_id != null
        );

        this.isEmployeeLoading = false;
      } catch (e) {}
    },
    async getData() {
      this.isLoading = true;
      let date = moment().format("YYYY-MM-DD");
      let form = await axios.get(
        `/users/${this.user.id}/user_punches?date=${date}`
      );
      if (form.data.data.length) {
        this.form = form.data.data;
        let today_in = this.form.find((item) => item.state === "IN");
        this.inData = today_in;
        if (today_in) {
          this.inState = true;
        } else {
          this.inState = false;
        }

        let today_out = this.form.find((item) => item.state === "OUT");
        this.outData = today_out;
        if (today_out) {
          this.outState = true;
        } else {
          this.outState = true;
        }
      }
      console.log("Form");
      console.log(this.form);
      this.isLoading = false;
    },
    getInCurrentLocation() {
      this.isInLoading = true;
      if (navigator.geolocation) {
        console.log(this.currentLocation);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position);
            // Success callback
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.currentLocation.latitude = latitude;
            this.currentLocation.longitude = longitude;
            this.getInAddressFromCoordinates(latitude, longitude);

            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
          },
          (error) => {
            // Error callback
            console.error("Error getting the current location:", error.message);
          }
        );
      } else {
        // Geolocation not supported
        console.error("Geolocation is not supported by this browser.");
      }
    },
    getOutCurrentLocation() {
      this.isOutLoading = true;
      if (navigator.geolocation) {
        console.log(this.currentLocation);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position);
            // Success callback
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.currentLocation.latitude = latitude;
            this.currentLocation.longitude = longitude;
            this.getOutAddressFromCoordinates(latitude, longitude);

            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
          },
          (error) => {
            // Error callback
            console.error("Error getting the current location:", error.message);
          }
        );
      } else {
        // Geolocation not supported
        console.error("Geolocation is not supported by this browser.");
      }
    },

    async getInAddressFromCoordinates(latitude, longitude) {
      // const apiKey = "03c48dae07364cabb7f121d8c1519492";
      // const apiKey = "AIzaSyDHIpiCt5LbStiDkGwQJR2u0z96cVGCW5I";
      // const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;/

      const apiKey = "AIzaSyAm0FfE4ijnd7gx8dQ6KHp2rtOWNs9rYZk";
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
      // const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

      try {
        const response = await fetch(url);
        console.log("Google response ======>", response);
        const data = await response.json();
        console.log("Google data ======>", data);
        this.address = data.results[0].formatted_address;
        console.log("Google address ======>", this.address);
      } catch (error) {
        this.error = "Error fetching address from coordinates";
      }

      let in_time = moment().format("hh:mm:ss A");
      let date = moment().format("YYYY-MM-DD");
      this.in_time = in_time;
      console.log(this.user);
      console.log(this.form);

      this.form.address = this.address;
      this.form.date = date;
      this.form.time = in_time;
      this.form.state = "IN";
      this.form.source = "MOBILE";
      this.form.latitude = this.currentLocation.latitude;
      this.form.longitude = this.currentLocation.longitude;
      this.form.remark = "";
      this.form.battery_level = "";
      this.form.session_type_id = 128;
      await axios.post(`/users/${this.user.id}/user_punches`, this.form);
      this.getData();
      this.isInLoading = false;
      console.log(this.form);
      this.inState = true;
    },
    async getOutAddressFromCoordinates(latitude, longitude) {
      this.form = {};
      // const apiKey = "03c48dae07364cabb7f121d8c1519492";
      // const apiKey = "AIzaSyDHIpiCt5LbStiDkGwQJR2u0z96cVGCW5I";
      // const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;

      const apiKey = "AIzaSyAm0FfE4ijnd7gx8dQ6KHp2rtOWNs9rYZk";
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
      console.log(url);
      try {
        const response = await fetch(url);
        console.log("Google response ======>", response);
        const data = await response.json();
        console.log("Google data ======>", data);
        this.address = data.results[0].formatted_address;
        console.log("Google address ======>", this.address);
      } catch (error) {
        this.error = "Error fetching address from coordinates";
      }

      let out_time = moment().format("hh:mm:ss A");
      this.out_time = out_time;
      let out_date = moment().format("YYYY-MM-DD");

      this.form.user_id = this.user.id;
      this.form.address = this.address;
      this.form.date = out_date;
      this.form.time = out_time;
      this.form.state = "OUT";
      this.form.source = "MOBILE";
      this.form.latitude = this.currentLocation.latitude;
      this.form.longitude = this.currentLocation.longitude;
      this.form.remark = "";
      this.form.battery_level = "";
      this.form.session_type_id = 128;

      await axios.post(`/users/${this.user.id}/user_punches`, this.form);
      this.isOutLoading = false;
      this.getData();

      console.log(this.form);
    },

    // updateCalendar() {
    //   this.selectedDate = new Date(this.selectedYear, this.selectedMonth, 0);
    // },
    async getMonthlyAttendanceData(user_id) {
      this.isLoading = true;
      let MonthYear = this.form.month_year_filter_formatted.split("-");
      let getmonth = moment().month(MonthYear[0]).format("M");
      let month = this.selectedMonth != "" ? this.selectedMonth : getmonth;
      let year = this.selectedYear != "" ? this.selectedYear : MonthYear[1];
      this.selectedDate = new Date(year, month, 0);
      let events = [];
      let userId = user_id ?? this.user.id;
      this.employee_id = userId;
      let userAttendances = await axios.get(
        `/attendanceMonthlyCalendar?userId=${userId}&year=${year}&month=${month}`
      );
      this.userAttendances = userAttendances.data.data;

      this.userAttendances.attendances.forEach((attendance) => {
        let name = "";
        name = attendance.status;

        events.push({
          name: name,
          start: attendance.date,
          color: attendance.color,
          detail: attendance.detail,
          // end: "2024-08-05",
        });
        this.events = events;
      });
      console.log(this.events);
      this.isLoading = false;
    },
    showEvent({ nativeEvent, event }) {
      console.log(event);
      const open = () => {
        if (event.name != "Absent") {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          );
        }
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    isEnableIn() {
      let response = false;
      if (this.form.length) {
        let today_in = this.form.find((item) => item.state === "IN");
        if (today_in) {
          response = false;
        } else {
          response = true;
        }
      } else {
        response = true;
      }
      console.log("Enble In Status: " + response);
      return response;
    },
    isEnableOut() {
      let out_response = false;
      if (this.form.length) {
        let today_out = this.form.find((item) => item.state === "OUT");
        if (today_out) {
          out_response = false;
        } else {
          out_response = true;
        }
      } else {
        out_response = false;
      }
      console.log("Enble Out Status: " + out_response);
      return out_response;
    },
    async getUserDailyTasks({ date }) {
      console.log(
        `clieck to view date: ${date} and employee id: ${this.employee_id}`
      );

      this.userDailyTaskDialog = true;

      let query = `userId=${this.employee_id}&date=${date}`;

      let user_daily_tasks = await axios.get(
        `users/${this.employee_id}/user_daily_summaries?${query}`
      );
      this.user_daily_tasks = user_daily_tasks.data.data;
      // console.log(this.user_daily_tasks);
      // this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.sticky-column {
  position: sticky;
  left: 0;
  background-color: white; /* Match background color of table header */
  z-index: 1; /* Ensure the fixed column appears above other columns */
}
.sticky-header {
  position: sticky;
  left: 0;
  background-color: white; /* Match background color of table header */
  z-index: 3 !important; /* Ensure the fixed column appears above other columns */
}
.text-right {
  text-align: right;
  padding-right: 5px;
}
</style>

<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="ma-0">
          <v-col align="left" style="max-width: 40px">
            <img
              :src="require('@/assets/voe_imgs/logo.jpeg')"
              alt="Profile"
              height="50"
              width="50"
            />
          </v-col>
          <v-col>
            <v-row class="ma-0">
              <v-col class="blue--text text-h6 font-weighgt-bold pa-0">
                {{ user.full_name }}
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0">
                {{ calculateAge(user.dob) }} yrs,
                {{ user.title == "Mr." ? "Male" : "Female" }}
              </v-col>
              <v-col class="pa-0"> {{ user.employee_id }} </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Email</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.email"
              placeholder="Email"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Mobile Number</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.mobile"
              placeholder="Mobile"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Designation</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="designation"
              placeholder="Designation"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Father's Name</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.father_name"
              placeholder="Father Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Date of Birth</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.dob"
              placeholder="Date of Birth"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2 pb-8">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Blood Group</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.blood_group_code"
              placeholder="Blood Group"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {
        blood_group_code: "",
      },
      designation: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.user.id}`);
      this.form = form.data.data;
      this.form.blood_group_code =
        this.form.blood_group != null ? this.form.blood_group.code : "";

      const findDesignation = this.form.user_categories.find(
        (item) => item.category.category_name == "DESIGNATION"
      );

      this.designation = findDesignation
        ? findDesignation.category_list.name
        : "";
      this.isLoading = false;
    },
    calculateAge(birthdate) {
      const now = moment();
      const birthdateMoment = moment(birthdate);
      const years = now.diff(birthdateMoment, "years");
      return years;
    },
  },
};
</script>

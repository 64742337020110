<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col>
        <router-link to="/">
          <!-- <v-img
            class="mx-auto"
            :src="require('../../assets/logo.jpeg')"
            width="200"
          ></v-img> -->
          MOM
        </router-link>

        <br />

        <br />
        <br />
        <v-card elevation="2" class="mx-auto">
          <v-row>
            <v-col md="6">
              <v-img
                v-if="
                  loggedInRole == 'SCHOOL ADMIN' ||
                  loggedInRole == 'SUPER ADMIN'
                "
                height="400"
                :src="require('../../assets/images/admin_login_image.png')"
              ></v-img>
              <v-img
                v-if="loggedInRole == 'TEACHER'"
                height="400"
                :src="require('../../assets/images/teacher_login_image.png')"
              ></v-img>
              <v-img
                v-if="loggedInRole == 'STUDENT'"
                height="400"
                :src="require('../../assets/images/student_login_image.png')"
              ></v-img>
              <v-img
                v-if="loggedInRole == 'INFAKT TEACHER'"
                height="400"
                :src="require('../../assets/images/infakt_teacher_login.png')"
              ></v-img>
            </v-col>
            <v-col md="6">
              <br />
              <div
                class="text-h4 font-weight-bold text-center"
                v-text="loggedInRole + ' LOGIN'"
              ></div>
              <br />
              <v-card-text>
                <v-text-field
                  v-model="form.email"
                  outlined
                  label="Email"
                  prepend-inner-icon="mdi-email"
                  :error-messages="errors.email"
                ></v-text-field>

                <v-text-field
                  v-model="form.password"
                  type="password"
                  outlined
                  label="Password"
                  prepend-inner-icon="mdi-lock"
                  :error-messages="errors.password"
                ></v-text-field>
                <v-progress-circular
                  v-if="isLoading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-btn v-else color="primary" dark @click="login">
                  Login
                </v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loggedInRole: "",
      isLoading: false,
    };
  },
  mounted() {
    if (this.$route.path == "/superadmin/login")
      this.loggedInRole = "SUPER ADMIN";
    if (this.$route.path == "/admin/login") this.loggedInRole = "SCHOOL ADMIN";
    if (this.$route.path == "/teacher/login") this.loggedInRole = "TEACHER";
    if (this.$route.path == "/student/login") this.loggedInRole = "STUDENT";
    if (this.$route.path == "/infakt_teacher/login")
      this.loggedInRole = "INFAKT TEACHER";
  },
  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async login() {
      this.isLoading = true;
      this.logIn(this.form)
        .then(() => {
          location.reload("/pan-dashboard");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
